import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from '@apollo/client/core'

// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: `https://${window.location.hostname}/api/graphql`,
    credentials: 'same-origin'
})

// Cache implementation
const cache = new InMemoryCache({
    addTypename: true
})


const authLink = new ApolloLink((operation, forward) => {
    const schoolUuid = window.__passdata.school.uuid

    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            schoolUuid,
        }
    }));

    return forward(operation);
})
// Create the apollo client
const client = new ApolloClient({
    cache,
    link: from([authLink, httpLink]),
    connectToDevTools: true,
})

export default client
